import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ReCAPTCHA from "react-google-recaptcha";
import {Container,Row,Col,Form,Button} from 'react-bootstrap'
//import GoogleMap  from "../components/map"
import SEO from "../components/seo"

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  fullname: '',
                        name: 'contact', 
                        phone :'',
                        company:'',
                        businesstype: '',
                        errorPhone: '',
                        errorEmail: '',
                        errorCaptcha: '',
                        errorField: '',
                        dataValue: false,
                        verified: false,
                        captcha : `${process.env.CAPTCHA_SITE_KEY}`
                    };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this) 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
        if(event.target.name === 'email'){
            const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
            }else if(pattern){
                this.setState({ errorEmail: '', dataValue: false });
            }
        }

        if(event.target.name === 'phone'){
            const pattern = /^[0-9-+#*]*$/;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
            }else if(pattern){
                this.setState({ errorPhone: '', dataValue: false });
            }
        }
    }

    onChange = (value) => {
        this.setState({
            verified: true
        })
    };


    handleSubmit = (event) => {
        const {businesstype} = this.state;
        if(businesstype === '' || businesstype=== 'Business Name'){
            this.setState({ errorField: 'Plese Select atleast one Business', dataValue: false });
        }else if (!this.state.verified) {
            this.setState({ errorCaptcha: 'Please verify Captcha', dataValue: false });
        }else if (this.state.errorEmail!=='') {
            this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
        }else if (this.state.errorPhone!=='') {
            this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
        } else {

            fetch(`${process.env.GATSBY_API_URL}/contact`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state)
            }).then(function (response) {
                if(response.status === 200){
                    alert("Email Sent Successfully");
                    window.location.reload();
                }else{
                    alert("Couldn't Send email");
                    window.location.reload();
                }
                //return response.json();
            });
        }

        event.preventDefault();
    }
    render() {
    return(
        <Layout>
            <SEO title="Make Contact" />
            <div >
                <Image alt="home automation" filename="contact.png"  />
                    <div className="abtImage">
                        <h1 style={{fontWeight:"bold",color:"white",fontSize:"44px"}}>MAKE CONTACT</h1>
                    </div>
            </div>
            <Container className="contactForm">
            <br/>
            <div className="contactRow">Don't wait, learn how Trace Width can be the total solution for your business today!</div>
                <p className="contactPara" style={{textAlign:"center"}}>Fill out the form below and lets get in touch.</p>
                <form method="post" onSubmit={this.handleSubmit}>
                
                    <Form.Group>
                        <Row className="mt-2">
                            <Col sm={12} lg={6} className="mt-2">
                                <Form.Control 
                                type="text" 
                                className="inputForm" 
                                placeholder="Full Name" 
                                required="required"
                                value={this.state.fullname} 
                                name="fullname" 
                                onChange={this.handleChange} 
                                />
                            </Col>
                            <Col sm={12} lg={6} className="mt-2">
                                <Form.Control type="text" 
                                className="inputForm"   
                                placeholder="Phone Number"
                                required="required"
                                value={this.state.phone} 
                                name="phone" 
                                onChange={this.handleChange}  />
                                <span style={{ color: "red", fontSize: "12px" }} id="emailspan">{this.state.errorPhone} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6} className="mt-2">
                           
                                <Form.Control 
                                type="text" 
                                className="inputForm" 
                                required="required"
                                placeholder="Email"
                                value={this.state.email} 
                                name="email" 
                                onChange={this.handleChange}  />
                                 <span style={{ color: "red", fontSize: "12px" }}>{this.state.errorEmail}</span>
                            </Col>
                          
                            <Col sm={12} lg={6} className="mt-2">
                                <Form.Control 
                                type="text" 
                                className="inputForm" 
                                placeholder="Business Name"
                                required="required"
                                value={this.state.company} 
                                name="company" 
                                onChange={this.handleChange}  
                                />
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} lg={6} className="mt-2">
                                <Form.Control type="text" 
                                placeholder="Postal Code"
                                required="required" 
                                className="inputForm"
                                value={this.state.pincode} 
                                name="pincode" 
                                onChange={this.handleChange}  />
                            </Col>
                            <Col sm={12} lg={6} className="mt-2">
                                <Form.Control as="select" 
                                   defaultValue="Type of Business" 
                                   
                                   className="inputForm" 
                                   value={this.state.businesstype} 
                                   name="businesstype" 
                                   onChange={this.handleChange} >
                                    <option>Type of Business</option>
                                    <option>Engineering</option>
                                    <option>Government</option>
                                    <option>Military</option>
                                    <option>Healthcare</option>
                                    <option>Finance</option>
                                    <option>Automotive</option>
                                    <option>Aerospace</option>
                                    <option>Laboratory</option>
                                    <option>Manufacturing</option>
                                    <option>Marketing</option>
                                    <option>Web Design</option>
                                    <option>Mobile App Design</option>
                                    <option>Other</option>
                                </Form.Control>
                                <span style={{ color: "red", fontSize: "12px" }}>{this.state.businesstype ? '' : this.state.errorField}</span>
                            </Col>
                        </Row>
                        <Row className=" headerformRow1 mt-3 ml-0">
                        <Form.Control as="textarea" rows={3} placeholder="Your Message" className="inputForm" name="message" value={this.state.message} onChange={this.handleChange}/>
                        </Row>
                    </Form.Group>
                
                <ReCAPTCHA className="mt-3 ml-0"
                     sitekey={this.state.captcha}
                    onChange={this.onChange}>
                        <span style={{ color: "red", fontSize: "12px" }}>{this.state.verified ? '' : this.state.errorCaptcha}</span>
                    </ReCAPTCHA>
                
                <Button className="cntButton mt-3 ml-0" type="submit" value="Submit">
                    SUBMIT
                </Button>
                </form>
                <hr className="embhr" />
            </Container>

            <Container>
                <Row>
                    <Col sm={4}>
                        <h6 style={{fontWeight:"bold"}}>Trace Width Technology Solutions:</h6>
                        <p className="contactPara1">P.O. Box 2228<br/>
                        Oldsmar, FL. 34677 <br/>
                        Call Us: (727) 656-5030<br/>
                        <a href="mailto:sales@tracewidth.com">sales@tracewidth.com</a></p>
                    </Col>
                    <Col>
                        <iframe title="Trace Width"  width="100%" height="300" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Trace%20Width%20Technology%20Solutions,%20LLC.28870%20US%20Hwy%2019%20N%20%20FL+(Trace%20Width%20Technology%20Solutions,%20LLC.)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
                        <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=80fe83cf87c0e495c391713019850372e92b7ff5'></script>
                    </Col>
                </Row>
            </Container>

        </Layout>
    );
    }
};

export default Contact;